body {
    overflow: hidden;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    height: 100vh !important;
    width: 100vw !important;
    background-color: rgb(20, 20, 20);
}

.App {
    width: 100vw;
    height: 100vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}


.entorno {
    width: 100%;
    height: 100%;
    position: relative;
}

#target{
    height: 720px;
    width: 1280px;
    background-color: rgb(20, 20, 20);
}

p, h1, h2, h3, h4, h5, h6, a {
    margin: 0;
    text-decoration: none;
}