.modal {
    background-color: rgb(20, 20, 20);
    min-width: 100px;
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 950;
}

.modal.openModal {
    display: block;
}
.modal.closeModal {
    display: none;
}

.modal .maxModal {
    display: block;
}
.modal .minModal {
    display: none;
}

.modal .header {
    height: 20px;
    padding: 5px 15px;
    width: calc(100% - 30px);
    position: relative;
    display: inline-flex;
    align-items: center;
}

.modal .icon {
    margin-right: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.modal .title {
    width: calc(100% - 40px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.modal .close {
    position: absolute;
    bottom: 5px;
    right: 8px;
    font-size: 20px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    text-align: center;
}
.modal .min {
    position: absolute;
    bottom: 5px;
    right: 33px;
    font-size: 20px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    text-align: center;
}

.modal .content {
    overflow: auto;
    width: auto;
    padding: 10px;
}