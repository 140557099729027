#landing{
    background-color: rgb(20, 20, 20);
    height: 720px;
    width: 1280px;
}

#landing .landing_content{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    /* background-color: wheat; */
}

.logo_inicio {
    width: 300px;
    height: 100px;
}

.landingLogo_content {
    position: relative;
    height: 250px;
    width: 275px;
    margin-bottom: 40px;
}

.landingLogo {
    position: absolute;
    height: 250px;
}

.noGlitch {
    z-index: 4;
}

.glitch1{
    filter: opacity(.2);
    animation: glitch1 3s infinite linear;
    z-index: 0;
}

@keyframes glitch1 {
    0%{
        left: -10px;
    }
    5%{
        left: 0px;
    }
}

.glitch2{
    filter: opacity(.3) invert(1);
    animation: glitch2 1s infinite linear;
    z-index: 0;
}

@keyframes glitch2 {
    0%{
        left: 10px;
        bottom: -5px;
    }
    25%{
        left: 0px;
        bottom: 0px;
    }
}

.landingMenu{
    font-family: 'Tahoma';
    margin: 0 0 20px 0;
    letter-spacing: 5px;
    font-size: 20px;
    filter: opacity(.5);
}

.landingMenu:hover{
    filter: opacity(1);
    cursor: pointer;
    transform: skew(10deg, 0deg);
}

.landingHelp {
    position: absolute;
    left: 15px;
    bottom: 5px;
}

.landingVer {
    position: absolute;
    bottom: 5px;
    right: 15px;
}

.landingHelp h2, .landingVer h5{
    margin: 0;
}