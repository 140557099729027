#IconDesk.iconXD {
    /* 10 */
    height: 72px;
    /* 16 */
    width: 80px; 
    /* background-color: red; */
    position: absolute;
    /* top: 100px;
    left: 600px; */

    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 8 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    background: none !important;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0)) !important;
}

#IconDesk.iconXD:hover {
    overflow: inherit;
    cursor: pointer;
    filter: drop-shadow(0px 0px 5px gray) !important;
}

#IconDesk.iconXD svg{
    font-size: 28px;
}