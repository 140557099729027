/* Font */

.themeFont.green {
    color: rgba(76, 179, 76) !important;
}
.themeFont.white {
    color: rgb(214, 214, 214) !important;
}
.themeFont.pink {
    color: rgb(179, 76, 76) !important;
}

/* Links */

.themeFont.green a{
    color: rgba(76, 179, 76) !important;
}
.themeFont.white a{
    color: rgb(214, 214, 214) !important;
}
.themeFont.pink a{
    color: rgb(179, 76, 76) !important;
}

.themeFont.green a:hover{
    color: rgb(148, 221, 148) !important;
}
.themeFont.white a:hover{
    color: gray !important;
}
.themeFont.pink a:hover{
    color: rgb(179, 76, 76) !important;
}

/* Background */

.themeBG.green {
    background-color: rgba(76, 179, 76) !important;
}
.themeBG.white {
    background-color: rgb(214, 214, 214) !important;
}
.themeBG.pink {
    background-color: rgb(179, 76, 76) !important;
}


/* Fill */

.themeSVG.green .cls-4 {
    fill: rgba(76, 179, 76) ;
}
.themeSVG.white .cls-4{
    fill: rgb(204, 204, 204) ;
}
.themeSVG.pink .cls-4{
    fill: rgb(179, 76, 76) ;
}

/* Border */

.themeBorder.green {
    border-width: 1px;
    border-color:  rgba(76, 179, 76);
    border-style: solid;
}
.themeBorder.white {
    border-width: 1px;
    border-color: rgb(214, 214, 214);
    border-style: solid;
}
.themeBorder.pink {
    border-width: 1px;
    border-color: rgb(179, 76, 76);
    border-style: solid;
}

/* Image */

.themeImg.green {
    filter: contrast(1) !important;
}
.themeImg.white {
    filter: grayscale(1) !important;
}
.themeImg.pink {
    filter: hue-rotate(251deg) !important;
}

.themeBoxShadow.green {
  box-shadow: 3px 3px 0px rgba(76, 179, 76) !important;
}
.themeBoxShadow.white {
  box-shadow: 3px 3px 0px rgb(214, 214, 214)  !important;
}
.themeBoxShadow.pink {
  box-shadow: 3px 3px 0px rgb(179, 76, 76) !important;
}

/* Hr */
hr{
  border: 0px solid black;
  height: 1px;
}

hr.green{
  background-color: rgba(76, 179, 76) !important;
}
hr.white{
  background-color: rgba(214, 214, 214) !important;
}
hr.pink{
  background-color: rgb(179, 76, 76) !important;
}

/* Scroll Bar */

.themeScroll.green::-webkit-scrollbar-thumb {
  background: rgba(76, 179, 76);
  border-radius: 10px;
  cursor: pointer;
}
.themeScroll.white::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  cursor: pointer;
}
.themeScroll.pink::-webkit-scrollbar-thumb {
  background: rgb(179, 76, 76);
  border-radius: 10px;
  cursor: pointer;
}

/* Input Range */
input[type=range].themeRange.green::-webkit-slider-thumb {
  background: rgba(76, 179, 76);
}
 input[type=range].themeRange.white::-webkit-slider-thumb {
  background: white;
}
 input[type=range].themeRange.pink::-webkit-slider-thumb {
  background: rgb(179, 76, 76);
}

input[type=range].themeRange.green::-moz-range-thumb {
  background: rgba(76, 179, 76);
}
input[type=range].themeRange.white::-moz-range-thumb {
  background: white;
}
input[type=range].themeRange.pink::-moz-range-thumb {
  background: rgb(179, 76, 76);
}


/* ================================= */
/* Elements */

.handle {
  cursor: grab;
  z-index: 10;
}
.handle:active {
  cursor: grabbing;
  z-index: 11 !important;
}

.button {
    width: fit-content;
    font-size: 18px;
    padding: 5px 25px;
    text-align: center;
    cursor: pointer;
    filter: opacity(.7);
    border-radius: 5px;
    border: 2px !important;
}

.button p{
    margin: 0;
}

.button:hover {
    filter: opacity(1);
}


/* Scroll Bar */
::-webkit-scrollbar {
    width: 10px;
    height: 100%;
    z-index: 999;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: inherit;
    z-index: 999;
  }

  ::-webkit-scrollbar-track {
    background: rgb(22, 22, 22);
    border: 0px none #ffffff;
    border-radius: 0px;
    margin-top: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: rgb(22, 22, 22);
  }
  ::-webkit-scrollbar-track:active {
    background: rgb(22, 22, 22);
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }


  input[type=range] {
    -webkit-appearance: none;
    width: auto;
    background-color: transparent;
  }
  input[type=range]:focus {
    outline: none;
  }

  /* Barra No Seleccionada */
  input[type=range]::-webkit-slider-runnable-track {
    width: auto;
    height: 10px;
    cursor: pointer;
    background: gray;
    border-radius: 15px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: gray;
  }

  /* Botoncito */
  input[type=range]::-webkit-slider-thumb {
    height: 18px;
    width: 18px;
    border-radius: 50px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000;
    background: gray;
    border-radius: 5px;
    border: 0px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 1px #000000;
    border: 0px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    cursor: pointer;
  }
